// Importar funções necessárias dos SDKs do Firebase
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// Configuração do Firebase para a aplicação web
const firebaseConfig = {
  apiKey: "AIzaSyAlbgsf6bX80fDS9w2d4ClcVTfkcOYAC4c",
  authDomain: "traviancloud-5680a.firebaseapp.com",
  databaseURL: "https://traviancloud-5680a-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "traviancloud-5680a",
  storageBucket: "traviancloud-5680a.appspot.com",
  messagingSenderId: "494540990863",
  appId: "1:494540990863:web:ca2121af88c857d58583fa",
  measurementId: "G-KXD1VEQ40G"
};

// Inicialize o Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Exportar o serviço de autenticação para uso em outras partes da aplicação
export const auth = getAuth(app);
