import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getDatabase, ref, get, child } from 'firebase/database';
import { auth } from './firebase/firebase';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';




function BotPage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    server: '',
    account: '',
    password: '',
    proxyUrl: '',
    proxyUrl1: '',
    proxyUrl2: '',
    runningBot: true,
    sendHeroToAdventures: true,
    aboveLife: 30,
    sendFarmList: true,
    timeInterval1: 5,
    timeInterval2: 10,
    checkIncomingAttacks: false,
    apiKey: '',
    farmListName: '',
    farmListNVillages: '',
    selectedVillage: '',
  });
  const [userId, setUserId] = useState(null);
  const [message, setMessage] = useState('');
  const [playerInfo, setPlayerInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasPurchased, setHasPurchased] = useState(null);
  const [authChecked, setAuthChecked] = useState(false);
  const [activeTab, setActiveTab] = useState('Login');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVillage, setSelectedVillage] = useState('');
  const [buildings, setBuildings] = useState([]);
  const [upgradeQueue, setUpgradeQueue] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState(null);

  useEffect(() => {
    if (selectedVillage) {
      const village = playerInfo?.villages.find(v => v.dataId === selectedVillage);
      if (village) setBuildings(village.buildings);
    } else {
      setBuildings([]);
    }
  }, [selectedVillage, playerInfo]);


  // Função para mover item para cima
  const moveItemUp = (index) => {
    if (index > 0) {
      const newQueue = [...upgradeQueue];
      [newQueue[index - 1], newQueue[index]] = [newQueue[index], newQueue[index - 1]];
      setUpgradeQueue(newQueue);
    }
  };

  const moveItemDown = (index) => {
    if (index < upgradeQueue.length - 1) {
      const newQueue = [...upgradeQueue];
      [newQueue[index], newQueue[index + 1]] = [newQueue[index + 1], newQueue[index]];
      setUpgradeQueue(newQueue);
    }
  };

  const removeItem = (index) => {
    setUpgradeQueue(upgradeQueue.filter((_, i) => i !== index));
  };

  
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Verificar se o usuário está autenticado e redirecionar se necessário
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserId(user);
      } else {
        navigate('/'); // Redireciona para a tela de login se não estiver autenticado
      }
      setAuthChecked(true);
    });

    // Cleanup da assinatura
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (selectedVillage && playerInfo) {
      const village = playerInfo.villages.find(v => v.dataId === selectedVillage);
      setBuildings(village ? village.buildings : []);
    }
  }, [selectedVillage, playerInfo]);

  const handleVillageChange = (e) => {
    setSelectedVillage(e.target.value);
    setBuildings([]);
    setUpgradeQueue([]);
  };


  // Função de logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/'); 
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
    }
  };

  // Tab change
  // const handleTabChange = (tab) => {
  //   setActiveTab(tab);
  //   setIsOpen(false); 
  // };

  // Função de logout
  // const handleLogout = async () => {
  //   try {
  //     await signOut(auth);
  //     navigate('/'); // Redireciona para a tela de login após o logout
  //   } catch (error) {
  //     console.error('Erro ao fazer logout:', error);
  //   }
  // };

  // Buscar o userId e verificar o status de purchased do usuário autenticado
  const fetchUserId = async (user) => {
    const database = getDatabase();
    const dbRef = ref(database);
    setIsLoading(true); // Inicia o carregamento
    try {
      const snapshot = await get(child(dbRef, `users`));
      if (snapshot.exists()) {
        const users = snapshot.val();
        const userIdKey = Object.keys(users).find(
          (key) => users[key].email === user.email
        );
        setUserId(userIdKey);

        // Verificar o status de purchased do usuário
        const userData = users[userIdKey];
        if (userData.purchased === 1) {
          setHasPurchased(true);
        } else {
          setHasPurchased(false);
          setMessage(t('purchaseRequired'));
        }
      }
    } catch (error) {
      console.error('Erro ao buscar userId:', error);
    } finally {
      setIsLoading(false); // Termina o carregamento
    }
  };

  // Buscar as informações do bot ao carregar a página
  useEffect(() => {
    const fetchBotData = async () => {
      if (userId && hasPurchased) {
        setIsLoading(true);
        try {
          const response = await fetch(`https://api.traviancloud.com:5001/control-bot/${userId}`);
          if (response.ok) {
            const data = await response.json();
            
            // Carrega as informações do jogador e a queue
            setPlayerInfo({
              status: data.status,
              playername: data.playername,
              herolife: data.herolife,
              pontoscultura: data.pontoscultura,
              aliance: data.aliance,
              villages: data.villages.map(village => ({
                name: village.name,
                dataId: village.dataId,
                coordX: village.coordX,
                coordY: village.coordY,
                buildings: village.buildings.map(building => ({
                  gid: building.gid,
                  name: building.name,
                  level: parseInt(building.level) // Converte o nível para número
                })),
                buildingQueue: village.buildingQueue?.map(queueItem => ({
                  id: queueItem.id,
                  targetLevel: queueItem.targetLevel
                })) || []
              }))
            });
  
            // Carrega o formData com os dados de configuração
            setFormData({
              server: data.config.login.server,
              account: data.config.login.account,
              password: data.config.login.password,
              runningBot: data.config.login.runingBot,
              sendHeroToAdventures: data.config.hero.sendHeroToAdventures,
              aboveLife: data.config.hero.aboveLife,
              sendFarmList: data.config.farmList.sendFarmList,
              timeInterval1: data.config.farmList.timeInterval1,
              timeInterval2: data.config.farmList.timeInterval2,
              checkIncomingAttacks: data.config.notification.checkIncomingAttacks,
              apiKey: data.config.notification.apiKey,
              proxyUrl1: data.config.login.proxyUrl1,
              proxyUrl2: data.config.login.proxyUrl2,
            });
  
            // Carrega a queue com os dados obtidos
            const loadedQueue = data.villages.flatMap(village =>
              village.buildingQueue?.map(queueItem => ({
                ...queueItem,
                villageId: village.dataId,
                villageName: village.name,
                level: queueItem.targetLevel - 1 // Nível atual para exibir corretamente
              })) || []
            );
            setUpgradeQueue(loadedQueue);
  
          } else {
            setMessage(t('errorMessage'));
          }
        } catch (error) {
          console.error('Erro ao buscar os dados do bot:', error);
          setMessage(t('errorMessage'));
        } finally {
          setIsLoading(false);
        }
      }
    };
  
    fetchBotData();
  }, [userId, hasPurchased, t]);
  
  // Sincronizar buildingQueue com a aldeia selecionada
  useEffect(() => {
    if (selectedVillage && playerInfo) {
      const village = playerInfo.villages.find(v => v.dataId === selectedVillage);
      if (village) {
        setBuildings(village.buildings || []);
        // Apenas adiciona ao `upgradeQueue` caso ainda não esteja lá
        setUpgradeQueue(prevQueue => [
          ...prevQueue,
          ...(village.buildingQueue || []).filter(queueItem =>
            !prevQueue.some(existingItem => 
              existingItem.villageId === village.dataId &&
              existingItem.id === queueItem.id &&
              existingItem.targetLevel === queueItem.targetLevel
            )
          ).map(queueItem => ({ ...queueItem, villageId: village.dataId }))
        ]);
      }
    } else {
      setBuildings([]);
    }
  }, [selectedVillage, playerInfo]);
  
  const handleAddToQueue = () => {
    if (selectedBuilding && selectedVillage) {
      const buildingToAdd = buildings.find(b => b.gid === selectedBuilding);
      if (buildingToAdd) {
        setUpgradeQueue(prevQueue => [...prevQueue, { ...buildingToAdd, villageId: selectedVillage }]);
        setSelectedBuilding(null); // Reseta a seleção
      }
    }
  };
  
  // Agrupa a fila de upgrade por aldeia
  const groupedQueue = upgradeQueue.reduce((acc, building) => {
    const village = playerInfo?.villages.find(v => v.dataId === building.villageId);
    const villageName = village?.name || "Unknown Village";
    if (!acc[villageName]) acc[villageName] = [];
    acc[villageName].push(building);
    return acc;
  }, {});
  


  // Atualizar os campos do formulário
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    if (name === 'selectedVillage') {
      // Armazena como uma string para split mais tarde
      setFormData((prev) => ({
        ...prev,
        selectedVillage: value // Exemplo: "coordX,coordY|dataId"
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : (type === 'number' ? parseInt(value, 10) || 0 : value),
      }));
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setIsOpen(false); // Fecha o menu quando uma aba é selecionada
  };

  const handleSubmit2 = async () => {
    if (!formData.selectedVillage) {
      console.error("Nenhuma vila selecionada.");
      setMessage(t('selectVillageMessage')); // Opcional: exibir mensagem para o usuário
      return;
    }
  
    // Verifique se `selectedVillage` está no formato correto antes de dividir
    let coords, dataId;
    try {
      [coords, dataId] = formData.selectedVillage.split('|');
      const [coordX, coordY] = coords.split(',');
  
      console.log("Coordenadas:", coordX, coordY, "ID:", dataId);
  
      const postData = {
        FarmListName: formData.farmListName,
        NumeroDeAldeias: parseInt(formData.farmListNVillages, 10),
        AldeiaID: dataId, // Defina o ID da aldeia corretamente
        CoordX: parseInt(coordX, 10),
        CoordY: parseInt(coordY, 10),
      };
  
      setIsLoading(true); // Inicia o carregamento
      const response = await fetch(`https://api.traviancloud.com:5001/create-farmlist/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });
  
      if (response.ok) {
        alert(t('farmListCreatedSucess'));
        console.log('Farm list criada com sucesso');
      } else {
        alert(t('farmListCreatedFail'));
        console.error('Erro ao criar farm list');
      }
    } catch (error) {
      console.error("Erro ao processar 'selectedVillage':", error);
      alert("Formato de 'selectedVillage' inválido. Verifique a entrada.");
    } finally {
      setIsLoading(false); // Termina o carregamento
    }
  };
  

  // Enviar os dados via POST
  
// Enviar os dados via POST
const handleSubmit = async (e) => {
  e.preventDefault();
  if (!userId || !hasPurchased) {
    setMessage(t('errorMessage'));
    return;
  }

  setIsLoading(true);

  // Construção do objeto `buildingQueue` no formato esperado com `Id` e `Level` capitalizados
  const buildingQueue = upgradeQueue.reduce((acc, building) => {
    const { villageId, gid, level } = building;
    if (!acc[villageId]) acc[villageId] = [];
    acc[villageId].push({ Id: parseInt(gid), Level: parseInt(level) + 1 }); // Próximo nível
    return acc;
  }, {});

  // Estrutura dos dados de `postData`
  const postData = {
    Login: {
      Server: formData.server,
      Account: formData.account,
      Password: formData.password,
      RuningBot: formData.runningBot,
    },
    Hero: {
      SendHeroToAdventures: formData.sendHeroToAdventures,
      AboveLife: parseInt(formData.aboveLife, 10),
    },
    FarmList: {
      SendFarmList: formData.sendFarmList,
      TimeInterval1: parseInt(formData.timeInterval1, 10),
      TimeInterval2: parseInt(formData.timeInterval2, 10),
    },
    Notification: {
      CheckIncomingAttacks: formData.checkIncomingAttacks,
      ApiKey: formData.apiKey,
    },
    ProxyConfig: {
      ProxyUrl1: formData.proxyUrl1 || "",
      ProxyUrl2: formData.proxyUrl2 || "",
    },
    BuildingQueue: buildingQueue, // Inclui a buildingQueue criada com Id e Level capitalizados
  };

  try {
    const response = await fetch(`https://api.traviancloud.com:5001/control-bot/${userId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });

    if (response.ok) {
      const data = await response.json();
      setMessage(t('successMessage'));

      setPlayerInfo({
        status: postData.Login.RuningBot ? t('active') : t('inactive'),
        playername: data.playername,
        herolife: data.herolife,
        pontoscultura: data.pontoscultura,
        aliance: data.aliance,
        villages: data.villages.map(village => ({
          name: village.name,
          dataId: village.dataId,
          coordX: village.coordX,
          coordY: village.coordY,
          buildings: village.buildings.map(building => ({
            name: building.name,
            level: building.level,
            gid: building.gid
          })),
          buildingQueue: buildingQueue[village.dataId] || [] // Associa a queue da vila
        }))
      });
    } else {
      const errorText = await response.text();
      setMessage(`${t('errorMessage')}: ${errorText}`);
    }
  } catch (error) {
    console.error('Erro ao enviar o POST:', error);
    setMessage(t('errorMessage'));
  } finally {
    setIsLoading(false);
  }
};

  if (!authChecked) {
    return <LoadingSpinner />;
  }
  
  
    
  

  return (
     <>
      <nav className="navbar">
      <div className={`navbar-toggle ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
          ☰
        </div>
        <div className={`sidebar ${isOpen ? 'open' : ''}`}>
          <ul className="navbar-list">
            <li className="navbar-item" onClick={() => handleTabChange('General')}>{t('generalSettings')}</li>
            <li className="navbar-item" onClick={() => handleTabChange('Login')}>{t('loginSettings')}</li>
            <li className="navbar-item" onClick={() => handleTabChange('Hero')}>{t('heroSettings')}</li>
            <li className="navbar-item" onClick={() => handleTabChange('FarmList')}>{t('farmListSettings')}</li>
            <li className="navbar-item" onClick={() => handleTabChange('AutoBuilding')}>{t('autobuilding')}</li>

            <li className="navbar-item" onClick={() => handleTabChange('Notifications')}>{t('notificationSettings')}</li>
            
            <li className="navbar-item" onClick={() => handleLogout()}>{t('logout')}</li>

          </ul>
        </div>
      </nav>
    <div className="App">
       
      <header className="App-header">
        <br />
        <h1>{t('title')}</h1>
        {/* <a onClick={handleLogout}>{t('logout')}</a> */}
        {hasPurchased === false && (
          <p className="warning">{t('purchaseRequired')}</p>
        )}
        {isLoading && <LoadingSpinner />}
        {!isLoading && hasPurchased && (
          <>
           
  
            {activeTab === 'Login' && (
              <div className="panel">
                <h3>{t('loginSettings')}</h3>
                <select
                  name="server"
                  value={formData.server}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    {t('server')}
                  </option>
                    <option value="https://ts1.x1.arabics.travian.com/">Arabics TS1 X1</option>
                    <option value="https://ts2.x1.arabics.travian.com/">Arabics TS2 X1</option>
                    <option value="https://ts3.x1.arabics.travian.com/">Arabics TS3 X1</option>
                    <option value="https://ts4.x1.arabics.travian.com/">Arabics TS4 X1</option>
                    <option value="https://ts5.x1.arabics.travian.com/">Arabics TS5 X1</option>
                    <option value="https://ts6.x1.arabics.travian.com/">Arabics TS6 X1</option>
                    <option value="https://ts7.x1.arabics.travian.com/">Arabics TS7 X1</option>
                    <option value="https://ts30.x3.arabics.travian.com/">Arabics TS30 X3</option>
                    <option value="https://ts1.x1.america.travian.com/">America TS1 X1</option>
                    <option value="https://ts2.x1.america.travian.com/">America TS2 X1</option>
                    <option value="https://ts3.x1.america.travian.com/">America TS3 X1</option>
                    <option value="https://ts4.x1.america.travian.com/">America TS4 X1</option>
                    <option value="https://ts5.x1.america.travian.com/">America TS5 X1</option>
                    <option value="https://ts6.x1.america.travian.com/">America TS6 X1</option>
                    <option value="https://ts8.x1.america.travian.com/">America TS8 X1</option>
                    <option value="https://ts20.x2.america.travian.com/">America TS20 X2</option>
                    <option value="https://ts30.x3.america.travian.com/">America TS30 X3</option>
                    <option value="https://ts1.x1.asia.travian.com/">Asia TS1 X1</option>
                    <option value="https://ts2.x1.asia.travian.com/">Asia TS2 X1</option>
                    <option value="https://ts3.x1.asia.travian.com/">Asia TS3 X1</option>
                    <option value="https://ts4.x1.asia.travian.com/">Asia TS4 X1</option>
                    <option value="https://ts5.x1.asia.travian.com/">Asia TS5 X1</option>
                    <option value="https://ts6.x1.asia.travian.com/">Asia TS6 X1</option>
                    <option value="https://ts20.x2.asia.travian.com/">Asia TS20 X2</option>
                    <option value="https://ts30.x3.asia.travian.com/">Asia TS30 X3</option>
                    <option value="https://ts50.x5.asia.travian.com/">Asia TS50 X5</option>
                    <option value="https://beta.travian.com/">Beta</option>
                    <option value="https://czsk.x1.czsk.travian.com/">CZSK X1</option>
                    <option value="https://ts1.x1.europe.travian.com/">Europe TS1 X1</option>
                    <option value="https://ts2.x1.europe.travian.com/">Europe TS2 X1</option>
                    <option value="https://ts3.x1.europe.travian.com/">Europe TS3 X1</option>
                    <option value="https://ts4.x1.europe.travian.com/">Europe TS4 X1</option>
                    <option value="https://ts5.x1.europe.travian.com/">Europe TS5 X1</option>
                    <option value="https://ts6.x1.europe.travian.com/">Europe TS6 X1</option>
                    <option value="https://ts7.x1.europe.travian.com/">Europe TS7 X1</option>
                    <option value="https://ts8.x1.europe.travian.com/">Europe TS8 X1</option>
                    <option value="https://ts9.x1.europe.travian.com/">Europe TS9 X1</option>
                    <option value="https://ts20.x2.europe.travian.com/">Europe TS20 X2</option>
                    <option value="https://ts31.x3.europe.travian.com/">Europe TS31 X3</option>
                    <option value="https://ts50.x5.europe.travian.com/">Europe TS50 X5</option>
                    <option value="https://ts1.x1.hispano.travian.com/">Hispano TS1 X1</option>
                    <option value="https://ts2.travian.co.id/">ID TS2</option>
                    <option value="https://ts5.travian.co.id/">ID TS5</option>
                    <option value="https://ts6.travian.co.id/">ID TS6</option>
                    <option value="https://ts19.travian.co.id/">ID TS19</option>
                    <option value="https://ts20.travian.co.id/">ID TS20</option>
                    <option value="https://ts29.travian.co.id/">ID TS29</option>
                    <option value="https://tx3.travian.co.id/">ID TX3</option>
                    <option value="https://ks1-cz.travian.com/">KS1 CZ</option>
                    <option value="https://ks2-cz.travian.com/">KS2 CZ</option>
                    <option value="https://ks3-cz.travian.com/">KS3 CZ</option>
                    <option value="https://ks4-cz.travian.com/">KS4 CZ</option>
                    <option value="https://ts1.x1.international.travian.com/">International TS1 X1</option>
                    <option value="https://ts3.x1.international.travian.com/">International TS3 X1</option>
                    <option value="https://ts4.x1.international.travian.com/">International TS4 X1</option>
                    <option value="https://ts5.x1.international.travian.com/">International TS5 X1</option>
                    <option value="https://ts6.x1.international.travian.com/">International TS6 X1</option>
                    <option value="https://ts7.x1.international.travian.com/">International TS7 X1</option>
                    <option value="https://ts8.x1.international.travian.com/">International TS8 X1</option>
                    <option value="https://ts9.x1.international.travian.com/">International TS9 X1</option>
                    <option value="https://ts20.x2.international.travian.com/">International TS20 X2</option>
                    <option value="https://ts30.x3.international.travian.com/">International TS30 X3</option>
                    <option value="https://ts31.x3.international.travian.com/">International TS31 X3</option>
                    <option value="https://finals.travian.com">TT Finals</option>
                    </select>
              <input
                type="text"
                name="account"
                placeholder={t('accountPlaceholder')}
                value={formData.account}
                onChange={handleChange}
                required
              />
              <br />
              <input
                type="password"
                name="password"
                placeholder={t('passwordPlaceholder')}
                value={formData.password}
                onChange={handleChange}
                required
              />
              <br />
              <input
                  type="text"
                  name="proxyUrl1"
                  placeholder={t('proxyurl1')}
                  value={formData.proxyUrl1}
                  onChange={handleChange}
                />
                <br />
                <input
                type="text"
                name="proxyUrl2"
                placeholder={t('proxyurl2')}
                value={formData.proxyUrl2}
                onChange={handleChange}
              />
              <br />
              <span style={{ color: 'red' }}>
                {t('proxyFrase')}
              </span>
            </div>
          )}

             
          {activeTab === 'Hero' && (
              <div className="panel">
                <h3>{t('panel2Title')}</h3>
                <br />

                <label>
                  <input
                    type="checkbox"
                    name="sendHeroToAdventures"
                    checked={formData.sendHeroToAdventures}
                    onChange={handleChange}
                  />
                  {' ' + t('sendHeroAdventures')}
                </label>
                <br />
                <br />

                {' ' + t('heroAboveLife') + ':'}
                <br />
                <input
                  type="number"
                  name="aboveLife"
                  placeholder={t('heroAboveLife')}
                  value={formData.aboveLife}
                  onChange={handleChange}
                  required
                />
              </div>
               )}

{activeTab === 'FarmList' && (
  <>
    <div className="panel">
      <h3>{t('panel3Title')}</h3>
      <label>
        <input
          type="checkbox"
          name="sendFarmList"
          checked={formData.sendFarmList}
          onChange={handleChange}
        />
        {' ' + t('sendFarmList')}
      </label>
      <p>{t('farmListinterval')}</p>
      <input
        type="number"
        name="timeInterval1"
        placeholder={t('timeInterval1')}
        value={formData.timeInterval1}
        onChange={handleChange}
        required
      />
      <p>{t('farmlistmiddle')}</p>
      <input
        type="number"
        name="timeInterval2"
        placeholder={t('timeInterval2')}
        value={formData.timeInterval2}
        onChange={handleChange}
        required
      />
      <p>{t('minutes')}</p>

    </div>
    <br />
    <br />
    {playerInfo ? (
      <div className="panel">
        <h3>{t('createFarmList')}</h3>
        <p>{t('createFarmListExplic')}</p>
        <br />
        <select
          id="villageSelect"
          name="selectedVillage" // Certifique-se de que o nome corresponde ao estado
          onChange={handleChange} // Função que processa a mudança de valor
          value={formData.selectedVillage} // Lendo o valor atual do formData
          defaultValue="" 
          required
        >
          <option value="" disabled>{t('selectvillage')}</option>
          {playerInfo?.villages.map((village, index) => (
            <option key={index} value={`${village.coordX},${village.coordY}|${village.dataId}`}>
              {`${village.name} (${village.coordX}, ${village.coordY}, ${village.dataId})`}
            </option>
          ))}
        </select>

        <br />
        <span style={{ color: 'red' }}>
        {t('alertNewFarmList')}
    </span>
    <br />
        <input
          type="text"
          name="farmListName"
          placeholder={t('farmListName')}
          onChange={handleChange}
          required
        />
        <br />
        <select
          name="farmListNVillages"
          onChange={handleChange}
          required
          defaultValue=""
        >
          <option value="" disabled>{t('selectNumberVillages')}</option>
          <option value="25">25 {t('villages')}</option>
          <option value="50">50 {t('villages')}</option>
          <option value="75">75 {t('villages')}</option>
          <option value="100">100 {t('villages')}</option>
          <option value="200">200 {t('villages')}</option>
          <option value="300">300 {t('villages')}</option>
          <option value="500">500 {t('villages')}</option>

        </select>
        <button onClick={handleSubmit2}>{t('createFarmListBtn')}</button>
      </div>
    ) : (
      <div className="panel">
        <h3>{t('createFarmList')}</h3>
        <br />
        <p>{t('createFarmListExplic')}</p>
        <br />
        <p>{t('playerInfoUnavailable')}</p>
      </div>
    )}
  </>
)}
             {activeTab === 'AutoBuilding' && (
              <>
              {playerInfo ? (
      <div className="panel">
        <h3>{t('Auto Building Board')}</h3>
        <label>Selecionar Aldeia:</label>
        <select value={selectedVillage} onChange={(e) => setSelectedVillage(e.target.value)}>
          <option value="">Escolha uma aldeia</option>
          {playerInfo?.villages.map(village => (
            <option key={village.dataId} value={village.dataId}>
              {village.name} (X: {village.coordX}, Y: {village.coordY})
            </option>
          ))}
        </select>

        {buildings.length > 0 && (
          <div className="building-selector">
            <label>Selecionar Edifício:</label>
            <select 
              value={selectedBuilding || ""} 
              onChange={(e) => setSelectedBuilding(e.target.value)}
            >
              <option value="">Escolha um edifício</option>
              {buildings.map((building, index) => (
                <option key={index} value={building.gid}>
                  {building.name} - Nível: {building.level}
                </option>
              ))}
            </select>
            <button className='button2' onClick={handleAddToQueue} disabled={!selectedBuilding}>
              Adicionar à Fila
            </button>
          </div>
        )}

        {Object.keys(groupedQueue).length > 0 && (
          <div className="upgrade-queue">
            <h4>Lista de Espera para Upgrade</h4>
            {Object.entries(groupedQueue).map(([villageName, buildings]) => (
              <div key={villageName}>
                <h5>{villageName}</h5>
                <ul>
                  {buildings.map((building, index) => (
                    <li key={`${villageName}-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
                      <span>{building.name} - Próximo Nível: {parseInt(building.level) + 1}</span>
                      <button className='button2' onClick={() => moveItemUp(index)} disabled={index === 0} style={{ marginLeft: '10px' }}>
                        ↑ Mover para Cima
                      </button>
                      <button className='button2' onClick={() => moveItemDown(index)} disabled={index === upgradeQueue.length - 1} style={{ marginLeft: '10px' }}>
                        ↓ Mover para Baixo
                      </button>
                      <button className='button2' onClick={() => removeItem(index)} style={{ marginLeft: '10px' }}>
                        Remover
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            {/* <button onClick={handleSubmitQueue}>Enviar Lista de Upgrade</button> */}
          </div>
        )}
        
        </div>
      ) : (
        <div className="panel">
          <br />
          <p>{t('playerInfoUnavailable')}</p>
        </div>
      )}
      </>
    )}
              


            {activeTab === 'Notifications' && (
              <div className="panel">
                <p>Under Maintenance</p>
                <p>Em Manutenção</p>

                {/* <h3>{t('panel4Title')}</h3>
                <label>
                  <input
                    type="checkbox"
                    name="checkIncomingAttacks"
                    checked={formData.checkIncomingAttacks}
                    onChange={handleChange}
                  />
                  {' ' + t('checkIncomingAttacks')}
                </label>
                <br />
                <input
                  type="text"
                  name="apiKey"
                  placeholder={t('apiKeyPlaceholder')}
                  value={formData.apiKey}
                  onChange={handleChange}
                /> */}
              </div>
              )}

            {activeTab === 'General' && (
              <>
                {playerInfo ? (
                  <div className='panel'>
                    <p><b>Status:</b> {playerInfo.status || ''}</p>
                    <p><b>{t('playername')}:</b> {playerInfo.playername || ''}</p>
                    <p><b>{t('herolife')}:</b> {playerInfo.herolife || ''}</p>
                    <p><b>{t('pontoscultura')}:</b> {playerInfo.pontoscultura || ''} / {t('day')} </p>
                    <p><b>{t('aliance')}:</b> {playerInfo.aliance || ''}</p>
                  </div>
                ) : (
                  <p>{t('loadingPlayerInfo')}</p> /* Mensagem opcional enquanto `playerInfo` carrega */
                )}

                <div className="panel">
                  <h3>{t('panel5Title')}</h3>
                  <form onSubmit={handleSubmit}>
                    <label>
                      <input
                        type="checkbox"
                        name="runningBot"
                        checked={formData.runningBot}
                        onChange={handleChange}
                      />
                      {' ' + t('activateBot')}
                    </label>
                    <br />
                    <button type="submit">{t('submitButton')}</button>
                  </form>
                </div>
              </>
            )}


          
            </>
      )}
    </header>
  </div>
  </>
);}

// Componente de loading
function LoadingSpinner() {
  const { t, i18n } = useTranslation();
  return (
    <div className="loading-spinner">
      <div className="spinner"></div>
      <div> 
      <br />
      <br />
      <br />     
      <br />
      {' ' + t('loadingMessage2')}</div>
    </div>
  );
}

export default BotPage;
