import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase/firebase'; // Importação do Firebase
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, set } from 'firebase/database';

function Register() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  // Função para alternar o idioma e salvar no localStorage
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const { name, email, password, confirmPassword } = formData;

    // Validação básica de campos
    if (!name || !email || !password || !confirmPassword) {
      alert(t('registerErrorFields'));
      return;
    }

    if (password !== confirmPassword) {
      alert(t('registerErrorPasswordMatch'));
      return;
    }

    try {
      // Criar o usuário no Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Salvar os dados adicionais na Realtime Database
      const database = getDatabase();
      const userRef = ref(database, `users/${user.uid}`);
      await set(userRef, {
        name,
        email,
        password,
        confirmPassword,
        purchased: 0,
        expireTime: "yyyy-mm-dd" // Definindo o campo purchased como 0 inicialmente
      });

      // Salvar o idioma no localStorage e redirecionar
      localStorage.setItem('language', i18n.language);
      navigate('/bot'); // Redireciona para a página do bot após o registro
    } catch (error) {
      console.error('Erro ao registrar o usuário:', error);
      alert(t('registerErrorGeneral') + ': ' + error.message);
    }
  };

  return (
    <div className="register-page">
      <h1>{t('registerTitle')}</h1>
      {/* <div style={{ marginBottom: '1rem' }}>
        <button onClick={() => changeLanguage('en')}>English</button>
        <button onClick={() => changeLanguage('pt')}>Português</button>
      </div> */}
      <form onSubmit={handleRegister}>
        <input
          type="text"
          name="name"
          placeholder={t('namePlaceholder')}
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder={t('emailPlaceholder')}
          value={formData.email}
          onChange={handleChange}
          required
        />
        <input
          type="password"
          name="password"
          placeholder={t('passwordPlaceholder')}
          value={formData.password}
          onChange={handleChange}
          required
        />
        <input
          type="password"
          name="confirmPassword"
          placeholder={t('confirmPasswordPlaceholder')}
          value={formData.confirmPassword}
          onChange={handleChange}
          required
        />
        <button type="submit">{t('registerButton')}</button>
      </form>
    </div>
  );
}

export default Register;
