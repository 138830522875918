// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './login'; // Importe o componente Login
import Register from './register'; // Importe o componente Register
import BotPage from './bot'; // Importe o componente BotPage
import { useTranslation } from 'react-i18next';

function App() {
  const { i18n } = useTranslation();

  // Verificar e definir o idioma inicial com base no localStorage ou no padrão 'en'
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'en';
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />       {/* Página principal (login) */}
          <Route path="/register" element={<Register />} /> {/* Página de registro */}
          <Route path="/bot" element={<BotPage />} />      {/* Página do bot */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
