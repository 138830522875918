import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from './firebase/firebase'; // Importação do Firebase
import { signInWithEmailAndPassword } from 'firebase/auth';

function Login() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Função para alternar o idioma e salvar no localStorage
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Autenticação do usuário com Firebase Authentication
      await signInWithEmailAndPassword(auth, email, password);
      localStorage.setItem('language', i18n.language);
      navigate('/bot'); // Redireciona para a página do bot após login bem-sucedido
    } catch (error) {
      // Exibir mensagem de erro
      setErrorMessage(t('loginError') );
    }
  };

  return (
    <div className="login-page">
      <h1>{t('loginTitle')}</h1>
      <div className="language-switcher">
        <button
          className={`language-btn ${i18n.language === 'en' ? 'active' : ''}`}
          onClick={() => changeLanguage('en')}
        >
          English
        </button>
        <button
          className={`language-btn ${i18n.language === 'pt' ? 'active' : ''}`}
          onClick={() => changeLanguage('pt')}
        >
          Português
        </button>
      </div>
      <form onSubmit={handleLogin}>
        <input
          type="email"
          placeholder={t('emailPlaceholder')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder={t('passwordPlaceholder')}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">{t('loginButton')}</button>
      </form>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <div style={{ marginTop: '1rem' }}>
        <p>{t('noAccount')}</p>
        <Link to="/register" className="register-link">
          {t('registerLink')}
        </Link>
      </div>
    </div>
  );
}

export default Login;
